<template>
  <div class="Settings">
    <h1>Settings</h1>
    <div v-for="categories in settingsCategories" :key="categories.label">
      <h2>{{categories.label}}</h2>
      <label v-for="setting in categories.settings" :key="setting.id">
        {{setting.label}}
        <br/>
        <div class="flex">
          <input v-model="setting.model" v-if="setting.type === 'input'"/>
          <select v-model="setting.model" v-else-if="setting.type === 'select'">
            <option :value='option.value' v-for="option in setting.options" :key='option.value'>
              {{option.label}}
            </option>
          </select>
          <ui-button
            v-else-if="setting.type === 'toggle'"
            class='ButtonToggleLayer Button--min'
            :active="setting.model"
            @click="setting.model = !setting.model"
          >
            <span :class="'icon-ui-' + (setting.model === true ? 'visible' : 'hidden')" />
            <span class='ButtonToggleLayer__text'>
            {{setting.model === true ? 'HIDE' : 'SHOW'}}
          </span>
          </ui-button>

          <ui-button class="" @click="setting.model = setting.defaultValue">Reset</ui-button>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button/Button.vue'
import preferences from '@/services/preferences'

export default {
  name: 'settings',
  components: {
    'ui-button': Button
  },
  data () {
    return {
      settingsCategories: [
        {
          label: 'General settings',
          settings: [{
            label: 'Last URL visited (used for restart the app)',
            key: 'lastURL',
            type: 'input',
            defaultValue: null
          }]
        }, {
          label: 'Map settings',
          settings: [{
            label: 'Animation frame rate',
            key: 'frameRate',
            type: 'input',
            defaultValue: 2
          }, {
            label: 'Nowcasting view',
            key: 'view',
            type: 'select',
            options: [{
              value: 'radar',
              label: 'Radar'
            }, {
              value: 'zoom',
              label: 'Zoom'
            }, {
              value: 'forecast',
              label: 'Next forecast'
            }],
            defaultValue: 'radar'
          }, {
            label: 'Default animation length',
            key: 'defaultAnimationLength',
            type: 'input',
            defaultValue: 30
          }, {
            label: 'Visibility of ground stations\' layer',
            key: 'layer-weatherStations',
            type: 'toggle',
            defaultValue: false
          }, {
            label: 'Visibility of osm background layer',
            key: 'layer-osm',
            type: 'toggle',
            defaultValue: true
          }, {
            label: 'Visibility of rainpath\'s path layer',
            key: 'layer-rainpath',
            type: 'toggle',
            defaultValue: false
          }]
        }
      ]
    }
  },
  created () {
    /**
     * For each settings, we set a new reactive data to the Vue instance
     * and add a watcher to update accordingly the preferences
     */
    this.settingsCategories.forEach((category, indexCategory) =>
      category.settings.forEach((setting, indexSetting) => {
        this.$set(setting, 'model', preferences[setting.key])
        this.$nextTick(() => {
          this.$watch(() => {
            return this.settingsCategories[indexCategory].settings[indexSetting].model
          }, (newValue) => {
            preferences[setting.key] = newValue
          })
        })
      }
      )
    )
  }
}
</script>

<style lang="postcss" scoped>
.Settings {
  width: 100%;
  margin: 2em auto;
  @media (min-width: $breakpoint-xs) {
    width: 40em;
  }
  h1 {
    text-align: center;
  }
  h2 {
    margin-top: 1em;
  }
  label {
    display: block;
    margin-bottom: $default-spacing;
  }
  input, .Button, select {
    padding: $default-spacing;
    margin-right: $default-spacing;
  }
  .ButtonToggleLayer {
    padding: 0 $default-spacing;
    [class^="icon-ui-"], [class*=" icon-ui-"] {
      position: relative;
      width: 1em;
      &:before {
        top: -2px;
        left: -5px;
        position: absolute;
      }
    }
  }
}

</style>
