/* eslint-disable no-case-declarations */
import { DatasetScale } from '@/views/CustomDashboard/definition'

export function setChartDatasetRanges (
  chartDataset,
  dataset,
  scale: DatasetScale,
  rangeMinKey = 'rangeMin',
  rangeMaxKey = 'rangeMax',
  rangeModeKey = 'rangeMode'
) {
  const minMaxAddition = {
    rainIntensityTotal: 0,
    pressureAddition: 5,
    windAddition: 5,
    temperatureAddition: 3,
    humidityAddition: 1
  }
  const currentMin = chartDataset[rangeMinKey]
  const currentMax = chartDataset[rangeMaxKey]
  let newMin = dataset.min
  let newMax = dataset.max
  switch (scale) {
    case 'temperature':
      newMin -= minMaxAddition.temperatureAddition
      newMax += minMaxAddition.temperatureAddition
      chartDataset[rangeMinKey] = currentMin !== null ? Math.min(newMin, currentMin) : newMin
      chartDataset[rangeMaxKey] = currentMax !== null ? Math.max(newMax, currentMax) : newMax
      chartDataset[rangeModeKey] = 'normal'
      break
    case 'humidity':
      newMin -= minMaxAddition.humidityAddition
      newMax += minMaxAddition.humidityAddition
      chartDataset[rangeMinKey] = Math.max(currentMin !== null ? Math.min(newMin, currentMin) : newMin, 0)
      chartDataset[rangeMaxKey] = currentMax !== null ? Math.max(newMax, currentMax) : newMax
      chartDataset[rangeModeKey] = 'tozero'
      break
    case 'pressure':
      newMin -= minMaxAddition.pressureAddition
      newMax += minMaxAddition.pressureAddition
      chartDataset[rangeMinKey] = Math.max(currentMin !== null ? Math.min(newMin, currentMin) : newMin, 0)
      chartDataset[rangeMaxKey] = currentMax !== null ? Math.max(newMax, currentMax) : newMax
      chartDataset[rangeModeKey] = 'normal'
      break
    case 'rain':
      chartDataset[rangeMinKey] = 0
      chartDataset[rangeMaxKey] = Math.max(dataset.max, currentMax)
      chartDataset[rangeModeKey] = 'tozero'
      break
    case 'wind':
      newMin -= minMaxAddition.windAddition
      newMax += minMaxAddition.windAddition
      chartDataset[rangeMinKey] = Math.max(currentMin !== null ? Math.min(newMin, currentMin) : newMin, 0)
      chartDataset[rangeMaxKey] = currentMax !== null ? Math.max(newMax, currentMax) : newMax
      chartDataset[rangeModeKey] = 'tozero'
      break
  }
}
