
















































export default {
  name: 'SeriesSettings',
  props: {
    series: {
      type: Array,
      required: true
    },
    dataset: {
      type: Array,
      required: true
    }
  }
}
