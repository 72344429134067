export const typesWidgets: {value: string|null; label: string}[] = [
  {
    value: 'unknown',
    label: ''
  },
  {
    value: 'service-information',
    label: 'Service Information'
  },
  {
    value: 'forecast-rain-information',
    label: 'Rain Forecast Report'
  },
  {
    value: 'weather-station-listing',
    label: 'Observation Summary'
  },
  {
    value: 'weather-station-listing-multi-station',
    label: 'Observation Summary Multi Station'
  },
  {
    value: 'weather-radar-map',
    label: 'Radar map'
  },
  {
    value: 'player',
    label: 'Player'
  },
  {
    value: 'ground-station-chart',
    label: 'Observation Chart'
  },
  {
    value: 'ground-station-chart-multi-station',
    label: 'Observation Chart Multi Station'
  },
  {
    value: 'forecast',
    label: 'Forecast Report (daily/hourly)'
  },
  {
    value: 'event-overview',
    label: 'Event Overview'
  },
  {
    value: 'compare-sessions',
    label: 'Compare Session'
  }
]
