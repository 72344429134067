






















































































import MapLegend from '@/components/map/MapLegend/MapLegend.vue'
import MapSettings from '@/components/map/MapSettings/MapSettings.vue'
import Button from '@/components/ui/Button/Button.vue'

export default {
  name: 'WrapperMapLegend',
  components: {
    'map-mapbox': () => import('@/components/map/MapboxGL/MapboxGL.vue'),
    'map-leaflet': () => import('@/components/map/Leaflet/Leaflet.vue'),
    'map-legend': MapLegend,
    'map-settings': MapSettings,
    'ui-button': Button
  },
  props: {
    id: {
      type: String,
      default: 'id1'
    },
    mapComponentToUse: {
      type: String,
      required: true
    },
    event: {
      type: Object,
      required: true
    },
    radarPeriods: {
      type: Array,
      default: () => ([])
    },
    stationsData: {
      type: Array,
      default: () => ([])
    },
    bbox: Object,
    currentTime: {
      type: Object,
      default: () => ({})
    },
    times: {
      type: Array,
      default: () => ([])
    },
    timesIndex: Number,
    imageResolution: String,
    params: {
      type: Object,
      default: () => ({
        displayLayersOsm: true,
        displayLayersRainpath: true,
        displayLayersWeatherStations: true,
        displayLayersWindArrows: true,
        osmOpacity: 1,
        radarOpacity: 1,
        airparifOpacity: 1,
        dataLayer: 'radar'
      })
    },
    numberOfForwardLayers: Number,
    rain: {
      type: Object,
      default: () => ({})
    },
    displayWarningMessage: {
      type: Boolean,
      default: false
    },
    warningMessage: {
      type: String
    },
    displayInfoMessage: {
      type: Boolean,
      default: false
    },
    airParifLayerAllowed: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isWindArrowsDisplayed: this.params.displayLayersWindArrows ?? false,
      isStationsDisplayed: this.params.displayLayersWeatherStations ?? false,
      isRainPathDisplayed: this.params.displayLayersRainpath ?? false,
      dataLayer: this.params.dataLayer ?? 'radar',
      osmLocalOpacity: this.params.osmOpacity ?? 1,
      radarLocalOpacity: this.params.radarOpacity ?? 1,
      airParifLocalOpacity: this.params.airParifOpacity ?? 1,
      displayLegend: false,
      displaySettings: false,
      mapBackground: 'light' as 'light' | 'dark' | 'shadedrelief' | 'satellite'
    }
  },
  methods: {
    updateMapBackground (name: 'light' | 'dark' | 'shadedrelief' | 'satellite') {
      this.mapBackground = name
    },
    toggleArrows (): void{
      this.isWindArrowsDisplayed = !this.isWindArrowsDisplayed
    },
    toggleLayer (layerName) {
      if (layerName === 'rainpath') {
        this.isRainPathDisplayed = !this.isRainPathDisplayed
      }
      if (layerName === 'weatherStations') {
        this.isStationsDisplayed = !this.isStationsDisplayed
      }
    },
    onOsmOpacityChange (value: number): void {
      this.osmLocalOpacity = value
    },
    onRadarOpacityChange (value: number): void {
      this.radarLocalOpacity = value
    },
    onUpdateDataLayer (value: 'airquality' | 'radar'): void {
      this.dataLayer = value
    }
  },
  watch: {
    params: {
      deep: true,
      handler () {
        this.isWindArrowsDisplayed = this.params.displayLayersWindArrows
        this.isStationsDisplayed = this.params.displayLayersWeatherStations
        this.isRainPathDisplayed = this.params.displayLayersRainpath
        this.osmLocalOpacity = this.params.osmOpacity || 1
        this.radarLocalOpacity = this.params.radarOpacity || 1
        this.dataLayer = this.params.dataLayer || 'radar'
      }
    }
  }
}
