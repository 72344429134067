




















































import Vue from 'vue'
import saveAs from 'file-saver'

import { fetchMessages } from '@/store/message/store'
import Button from '@/components/ui/Button/Button.vue'
import { vrsStore } from '@/store/'
import { getNowDateTime, stringifyDate } from '@/helpers/dates'

export default Vue.extend({
  components: {
    'ui-button': Button
  },
  props: {
    eventId: String
  },
  data: () => ({
    stateMessage: vrsStore.state.message
  }),
  async mounted () {
    // fetchMessages(vrsStore.state.app.data.eventId)
    if (this.eventId && !vrsStore.state.event.data) {
      await vrsStore.modules.event.actions.fetchEvent(this.eventId)
    }
    fetchMessages(vrsStore.state.event.data?.id)
  },
  methods: {
    exportCSV () {
      if (!vrsStore.state.message.apiData) return
      const csvData = []
      csvData.push('Championship,Time,Text\n')
      vrsStore.state.message.apiData.forEach(currentData => {
        csvData.push(
          (currentData.data.RaceWatchMessage.Championship ? currentData.data.RaceWatchMessage.Championship.$ : '') + ',' +
          currentData.data.RaceWatchMessage['@Timestamp'] + ',' +
          currentData.data.RaceWatchMessage.Text.$ + '\n'
        )
      })
      const blob = new Blob(csvData, { type: 'text/plain;charset=utf-8' })
      const now = stringifyDate(getNowDateTime())
      saveAs(blob, now + '_messages_' + vrsStore.state.event.data.code + '.csv')
    }
  }
})
