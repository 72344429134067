










export default {
  props: {
    displayAll: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Number, String],
      default: 0
    }
  }
}
