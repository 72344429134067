<template>
  <div>
    <p v-if="text" class="text-sm">{{ text }}</p>
    <div
      class="flex items-center mb-1"
    >
      <select
        multiple
        v-model="dataValue"
      >
        <option
          v-for="option in options"
          :key="option"
        >
          {{ option }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OverLimitSettings',
  props: {
    text: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      dataValue: this.value || []
    }
  },
  watch: {
    dataValue (val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style scoped>
select[multiple] {
  width: 100%;
  padding: 0.3rem;
  cursor: pointer;
  font-size: 1.2rem;
  background-color: #fff;
  height: 180px;
}

</style>
